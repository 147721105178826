import car from "./images/car.jpg"
import phoenix_art from "./images/phoenix_art.jpg"
import lamp from "./images/lamp.jpg"
import table1 from "./images/table1.jpg"
import table2 from "./images/table2.jpg"
import table3 from "./images/table3.jpg"
import table4 from "./images/table4.jpg"
import table5 from "./images/table5.jpg"
import tool_holder from "./images/tool_holder.jpg"
import hair_brooch from "./images/hair_brooch.jpg"


const projects = [    
    {
        image: car,
        categories: [
            "Design & Modeling"
        ],
        title: "'59 Corevette Surface Model",
        alt: "design and modeling, '59 Corvette surface model, CAD"
    },
    {
        image: phoenix_art,
        categories: [
            "Made To Order",
            "Art"
        ],
        title: "Phoenix Art",
        alt: "art, metal, made to order"
    },
    {
        image: lamp,
        categories: [
            "Design & Modeling",
            "3D Printing"
        ],
        title: "Dodecahedron Lamp Design",
        alt: "design and modeling, lamp, solid modeling, CAD"
    },
    {
        image: table1,
        categories: [
            "Made to Order",
            "Furniture"
        ],
        title: "Wooden Table",
        alt: "furniture, wood, made to order"
    },
    {
        image: hair_brooch,
        categories: [
            "Made To Order",
            "Crafts"
        ],
        title: "Hair Brooch",
        alt: "crafts, hair brooch"
    },
    {
        image: table2,
        categories: [
            "Made To Order",
            "Furniture"
        ],
        title: "Wooden Table",
        alt: "furniture, wood, made to order"
    },
    {
        image: table3,
        categories: [
            "Made To Order",
            "Furniture"
        ],
        title: "Wooden Table",
        alt: "furniture, wood, made to order"
    },
    {
        image: table4,
        categories: [
            "Made To Order",
            "Furniture"
        ],
        title: "Wooden Table",
        alt: "furniture, wood, made to order"
    },
    {
        image: table5,
        categories: [
            "Made To Order",
            "Furniture"
        ],
        title: "Wooden Table",
        alt: "furniture, wood, made to order"
    },
    {
        image: tool_holder,
        categories: [
            "Design & Modeling",
            "3D Printing"
        ],
        title: "Cr-10s Tool Holder",
        alt: "design and modeling, solid modeling, rapid prototyping, 3D printing"
    }
]

export default projects
