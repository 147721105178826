import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
//import Box from "@material-ui/core/Box"
import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"
import GridListTileBar from "@material-ui/core/GridListTileBar"
import Backdrop from "@material-ui/core/Backdrop"
//import Typography from "@material-ui/core/Typography"


import data from "../data/projects"


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
    },
    gridListTile: {
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",

        "&:hover": {
            opacity: 0.7,
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#000000",
    },
}))


const Projects = (props) => {
    const classes = useStyles()
    const [open, setOpen] = useState(data.map(item => false))

    const handleClick = (index) => {
        const newOpen = [...open].map((stat, i) => i !== index ? stat : !stat)
        setOpen(newOpen)
    }

    return (
        <div className={classes.root}>
            <GridList cellHeight={250}>
                { data.map((item, index) => (
                    <GridListTile
                        key={item.image}
                        cols={item.cols || 1}
                        onClick={(e) => handleClick(index)}
                        className={classes.gridListTile}
                    >
                        <img src={item.image} alt={item.alt} />
                        <GridListTileBar
                            title={item.title} 
                            subtitle={<span>{item.categories.join(" / ")}</span>}
                        />
                    </GridListTile>
                ))}
            </GridList>
            { data.map((item, index) => (
                <Backdrop open={open[index]} onClick={() => handleClick(index)} className={classes.backdrop}>
                    <img src={item.image} alt={item.alt} height="80%"/>
                </Backdrop>
            ))}
        </div>
    )
}

export default Projects
