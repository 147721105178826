import React from "react"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Projects from "../components/projects"


const OurWorkPage = () => {
    return (
        <Layout>
            <SEO title="Our Work" />
            <Container maxWidth="lg">
                <Box mt={12}>
                    <Typography variant="h1" gutterBottom>
                        Our Work
                    </Typography>
                    <Divider color="primary"/>
                </Box>
                <Box mt={12}>
                    <Projects />
                </Box>
            </Container>
        </Layout>
    )
}   

export default OurWorkPage
